.toast-enter {
  opacity: 0.01;
  transform: translateX(50%)
}
.toast-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 233ms ease-out;
}

.toast-exit {
  opacity: 1;
  transform: translateX(0);
}

.toast-exit-active {
  opacity: 0.01;
  transform: translateX(50%);
  transition: all 233ms ease-out;
}
