@import '../../scss/variables.scss';

.table-header {
  display: flex;
  height: 51px;
  padding: 10px;
  background: #555560;

  &__title {
    color: #fff;
    display: flex;
    align-items: center;
    margin-right: 30px;
    font-size: 18px;
    font-weight: bold;
    flex: 1;
  }

  &__items {
    display: flex;
  }

  &__btn {
    background: transparent;
    color: #00c3d7;
    border-bottom-width: 0;
    padding: 0;
    margin-right: 15px;
    outline-width: 0;

    i:first-child {
      margin-right: 5px;
    }
  }

  &__filter {
    color: #fff;
    display: flex;
    align-items: center;

    &+& {
      margin-left: 15px;
    }

    label {
      margin-bottom: 0;
    }

    label + button {
      margin-left: 5px;
    }

    select,
    .form-control {
      line-height: 1.5;
      height: 30px;
      background: #3B3B46;
      border-radius: 5px;
      border: none;
      color: #fff;
      font-size: 14px;
      margin-left: 5px;

      &:focus {
        border-width: 0;
        box-shadow: 0 0 0 2px #01adc9;
        outline: none;
      }
    }

    .rdt {
      margin-left: 5px;

      input {
        margin-left: 0;
      }
    }

    .react-select__container {
      margin-left: 5px;
    }
  }

  &__filter + &__search {
    margin-left: 15px;
  }

  &__search {
    position: relative;
    max-width: 330px;
    display: flex;

    > i {
      position: absolute;
      color: #fff;
      top: 7px;
      right: 10px;
    }
  }

  &__search-type {
    line-height: 1.5;
    color: #fff;
    font-size: 14px;
    padding: 1px 30px 0px 15px;
    background: #3B3B46;
    border: none;
    height: 30px;
    border-radius: 50px 0 0 50px;
  }

  &__search-input[type="search"] {
    display: block;
    line-height: 1.5;
    height: 30px;
    background: #3B3B46;
    border-radius: 500px;
    border: none;
    color: #fff;
    font-size: 14px;
    padding: 1px 30px 0px 15px;
  }
  &__search-input {

    &--with-type {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }



  &__button-icon {
    position: absolute;
    color: #fff;
    top: 5px;
    right: 10px;
    border-width: 0;
    background-color: transparent;
    border-radius: 50%;
    padding: 3px 5px 1px;
    line-height: 17px;

    &:hover,
    &focus {
      background-color: rgba(0, 0, 0, 0.28);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  table {
    color: $black;
  }

  &__count-items {
    margin-right: 8px;
    display: flex;
    align-items: center;
    color: #fff;
    border-right: 1px solid gray;
    padding-right: 8px;
  }

  &__is-loading {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31px;
    margin-right: 16px;
  }
}

.table-header {
  .react-select {
    &__container {
      width: 250px;
    }

    &__value-container {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__control {
      background: #3B3B46;
      border-width: 0;
      min-height: 30px;
    }

    &__single-value {
      color: inherit;
    }

    &__menu {
      color: #333333;
    }

    &__input {
      padding-left: 8px;
      padding-right: 8px;

      input {
        height: 22px;
        padding: 0;
        color: white !important;
      }
    }

    &__indicator {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .Select.is-disabled > .Select-control {
    background: transparent;
  }
}
