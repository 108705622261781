.modal-flex {
  justify-content: center;
  align-items: center;
}

.modal-check-email {
  .modal-content {
    background-color: transparent;
  }

  .modal-header {
    background-color: #01adc9;
    text-align: center;
    border-radius: 5px 5px 0 0;
    border-width: 0;

    h4 {
      font-size: 22px;
      font-weight: 700;
      color: white;
    }
  }

  .modal-body {
    background-color: #282c2d;
    color: white;
    border-radius: 0 0 5px 5px;

    p {
      font-size: 18px;
    }
  }
}