@import '../../scss/variables';

.switch-button {
  &__label {
    font-family: $font-primary-bold;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  &__text {
    display: inline-block;
    margin-left: 15px;
  }
}
