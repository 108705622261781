.submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  margin-left: 5px;
  .fa {
    line-height: inherit;
    margin-right: 5px;
  }
}