$gray1: #f2f2f2;
$gray2: #f9f9f9;
$gray3: #e4e4e4;
$gray4: #d2d2d2;
$gray5: #cccccc;
$gray6: #898989;
$gray7: #717171;
$gray8: #212121;

$blue1: #59c4d8;
$blue2: #00c3d7;
$blue3: #2B394F;
$blue4: #2a6496;
$blue5: #364760;
$blue6: #118DA7;

$red1: #ffb5b5;
$red2: #ff721b;
$red3: #E74C3C;

$orange1: #ffa736;
$orange2: #f8991d;
$orange3: #ff6e19;

$gray: #969696;
$orange: $orange2;
$red: $red3;
$green: #2ecc71;
$black: #000000;
$blue: $blue6;
$white: #ffffff;

$font-primary-bold: "CamphorPro-Bold", "Camphor", sans-serif;
$font-primary: "Camphor", sans-serif;
$font-size: 14px;

$success: $green;
$danger: #e74c3c;
$info: #3498db;
