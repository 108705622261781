.pagination {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .btn {
    width: auto;
  }
  .pagination-text {
    padding: 0 5px;
    white-space: nowrap;
  }
  .loading-icon {
    margin-left: 10px;
  }
}

.pagination-right {
  .loading-icon {
    order: -1;
    margin-right: 10px;
    margin-left: 0px;
  }
}