.note-form-add {
  margin-bottom: 30px;

  &__title {
    color: black;
    font-size: 16px;
    margin-bottom: 15px;
  }

  &__group {
    margin-bottom: 15px;
  }

  &__input {
    padding: 12px 16px;
  }

  &__helper {
    font-size: 14px;
    color: #ABADAD;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-top: 15px;
  }

  .submit-button {
    display: inline-flex;
    width: auto;
  }
}
