$gray: #969696;

.page-table {
  display: flex;
  align-items: flex-start;

  .ordered-table-header {
    display: flex;
    justify-content: space-between;
  }

  .scrollable-table {
    overflow-x: auto;
  }

  &__table {
    overflow-x: auto;
    max-width: 85vw;
  }

  &__filters {
    width: 240px;
    padding: 20px;
    margin-right: 20px;

    .close-button {
      width: 100%;
      display: flex;
      align-items: center;

      button {
        width: auto;
        background-color: lightgray;
        width: 20px;
        height: 20px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: none;
        border-bottom: none;
      }

      hr {
        margin: 0px;
        width: 100%;
        border-bottom: $gray 1px dashed;
      }
    }
  }
}
