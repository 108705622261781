
.group-fields {
  position: relative;

  &__button-close {
    position: absolute;
    top: 18px;
    right: -13px;
    width: auto;
    display: flex;
    align-items: center;

    button {
      background-color: lightgray;
      width: 20px;
      height: 20px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: none;
      border-bottom: none;
      cursor: pointer;

      &:hover {
        background-color: darken(lightgray, 5%)
      }
    }
  }
}
