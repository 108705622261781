.help-tooltip {
  background-color: #333333;
  .fa {
    font-size: .8em;
    color: white;
  }
  min-width: 1.25em;
  width: 1.25em;
  border-radius: 50%;
  height: 1.25em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: .8em;
  vertical-align: middle;

  &.medium {
    font-size: 14px;
  }
}

.help-tooltip-content {
  text-transform: initial;
  font-size: .8em;

  &.medium {
    font-size: 14px;
  }
}
