@import './_variables';
@import './submit-button';
@import './add-credits';

.align-self-start {
  align-self: flex-start;
}

.full-width {
  width: 100%;
}

a[data-disabled="true"] {
  opacity: .4;
  pointer-events: none;
  cursor: default;
}

.button-link {
  font-family: $font-primary-bold;
  color: $orange2;
  padding: 0;
  border-width: 0;
  background-color: transparent;

  &:hover {
    color: lighten($orange2, 10%);
  }
}
