.inbound-routes {
  color: #555;
  .fields-container {
    .form-group {
      flex: .2;
      padding: 15px;
    }
    .submit-container {
      flex: .43;
      display: flex;
      justify-content: flex-end;
    }
    &.stretched {
      .submit-container {
        flex: .2
      }
    }
  }
  .descriptions-container {
    display: flex;
    > div {
      margin: 2px;
    }
    p {
      font-size: 1em;
      color: #555;
      line-height: 1.2;
    }
    .spacer {
      height: 5px;
    }
    .edgestrategy-description {
      padding: 0px 15px;
      padding-left: 30px;
      flex: .4 0;
      min-width: fit-content;
    }
  }
  .paginated-table {
    table {
      tr > td {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      tr > td > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      tr {
        th {
          padding: 15px;
        }
        display: flex;
        .tb-alias,
        .tb-type,
        .tb-route,
        .tb-edge-set-strategy,
        .tb-actions {
          flex: .2;
          display: flex;
        }
        .react-bs-table-no-data {
          flex: 1;
        }
      }
      .btn {
        .fa {
          width: 15px;
        }
        margin: 0px;
        margin-right: 3px;
      }
    }
  }
}

.temp-routes-msg-orange {
  color: #FF991F;
}

@media (max-width: 850px) {
  .inbound-routes {
    .fields-container {
      flex-wrap: wrap;
      .form-group {
        margin-bottom: 15px;
      }
    }
    .descriptions-container {
      flex-direction: column;
    }
  }
}

@media (max-width: 1000px) {
  .inbound-routes {
    header {
      background-color: transparent;
    }
    .descriptions-container {
      .type-description {
        &.stretched {
          flex: .45;
        }
      }
    }
  }
}

