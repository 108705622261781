@keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}

.progress {
  margin-bottom: 0;
  border-radius: 0;
}

.progress-bar-striped,
.progress-striped .progress-bar {
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-size: 40px 40px;
}

.progress-bar.active,
.progress.active .progress-bar {
  animation: progress-bar-stripes 2s linear infinite;
}
