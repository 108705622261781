@keyframes note-updated {
  0% {
    background-color: white;
  }
  25% {
    background-color: darken(#EBF0F1, 10%);
  }
  100% {
    background-color: #EBF0F1;
  }
}


.note {
  display: flex;
  margin-left: 10px;
  background-color: #EBF0F1;
  border: 1px solid #EBF0F1;
  padding: 10px 10px 10px 17px;
  align-items: center;
  border-radius: 4px;
  text-align: left;
  position: relative;

  &:after,
  &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    transform: translate(0%, -50%);
  }

  &:before {
    border-width: 10px;
    border-right-color: #EBF0F1;
  }

  &--editing {
    background-color: white;
    border-color: #cccccc;

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-right-color: #ffffff;
      border-width: 10px;
    }
    &:before {
      border-width: 11px;
      border-right-color: #cccccc;
    }
  }
  &--updated {
    animation: note-updated .8s ease-out;
  }


  pre {
    padding: 0px;
    margin: 0px;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    background-color: transparent;
    border: none;
    border-radius: 0px;
    font-family: inherit;
  }

  &__content {
    flex: 1;
  }

  &__group {
    display: flex;
  }

  &__textarea {
    border-width: 0;
    font-size: 16px;
    padding: 3px 12px 3px 4px;
    font-size: 14px;
    flex: 1;

    &:focus {
      outline: none;
      border-width: 0;
    }
  }

  &__author {
    padding-top: 3px;
  }

  &__actions {
    flex: auto 0;
    margin-left: 10px;
    align-self: flex-end;

    .btn+.btn {
      margin-left: 2px;
    }
  }

  &__button-edit {
    padding: 6px;
    font-size: 20px;
    background-color: transparent;
    border-width: 0;
    outline-width: 0;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    height: auto;
    text-align: center;
    border-radius: 2px;
    color: black;

    &:hover {
      color: rgba(14, 5, 5, 0.7);
    }
    &:active {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &__button-cancel {
    padding: 6px;
    font-size: 20px;
    background-color: transparent;
    border-width: 0;
    outline-width: 0;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    height: auto;
    text-align: center;
    border-radius: 2px;
    color: #8e8e8e;

    &:hover {
      color: rgba(#8e8e8e, 0.7);
    }
    &:active {
      color: rgba(#8e8e8e, 0.5);
    }
    &[disabled] {
      color: #c4c4c4;
    }
  }

  &__button-confirm {
    padding: 6px;
    font-size: 20px;
    background-color: transparent;
    border-width: 0;
    outline-width: 0;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    height: auto;
    text-align: center;
    border-radius: 2px;
    margin-left: 10px;
    color: #2ecc71;

    &:hover {
      color: rgba(#2ecc71, 0.7);
    }
    &:active {
      color: rgba(#2ecc71, 0.5);
    }

    &[disabled] {
      color: #8e8e8e;
    }
  }
}
