.button-v2 {
  display: inline-block;
  cursor: pointer;
  border: 1px solid transparent;
  border-bottom-style: solid;
  border-bottom-color: #0197b0;
  border-radius: 4px;
  background-color: #01adc9;
  border-bottom-width: 4px;
  color: #fff;
  transition: 0.25s;
  font-size: 16px;
  outline: 0;
  line-height: 32px;
  border-bottom-width: 4px;
  font-family: "CamphorPro-Regular", "Camphor", "ProximaNova-Regular",
    "Proxima Nova", "Proxima", "Montserrat", sans-serif;
  padding: 4px 15px 0;

  &:focus,
  &:hover {
    color: #fff;
    background-color: #01bfde;
  }

  &:active {
    background-color: #018297;
    border-bottom-color: #01798d;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &[disabled] {
    background-color: #95a5a6;
    border-bottom-color: #798d8f;
    color: #fff;
  }

  &--danger {
    background-color: #e74c3c;
    border-bottom-color: #e43725;

    &:focus,
    &:hover {
      color: #fff;
      background-color: #e9594a;
    }

    &:active {
      background-color: #c22818;
      border-bottom-color: #b52516;
    }

    &[disabled] {
      background-color: #d9534f;
      border-color: #d43f3a;
    }
  }

  &--success {
    background-color: #2ecc71;
    border-bottom-color: #29b765;

    &:focus,
    &:hover {
      background-color: #3ad37a;
    }

    &:active {
      background-color: #239955;
      border-bottom-color: #208f4f;
    }

    &[disabled] {
      background-color: #5cb85c;
      border-color: #4cae4c;
    }
  }

  &--change {
    background-color: #ff721b;
    border-bottom-color: #fd6100;

    &:focus,
    &:hover {
      color: #fff;
      background-color: #f8833a;
    }

    &:active {
      background-color: #b34908;
      border-bottom-color: #ad4606;
    }

    &[disabled] {
      background-color: #c75209;
      border-color: #bb4d09;
    }
  }

  &--success {
    background-color: #2ecc71;
    border-bottom-color: #29b765;

    &:focus,
    &:hover {
      background-color: #3ad37a;
    }

    &:active {
      background-color: #239955;
      border-bottom-color: #208f4f;
    }

    &[disabled] {
      background-color: #5cb85c;
      border-color: #4cae4c;
    }
  }

  &--link {
    background: none;
    border-bottom: none;
    color: #333333;

    &:focus,
    &:hover {
      background-color: #f5f5f5;
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  &--small {
    font-size: 14px;
    line-height: 24px;
    border-bottom-width: 4px;
    padding: 2px 10px 0;
  }

  &--block {
    width: 100%;
  }
}
