@import '../../scss/variables';

.form-group {
  .react-select {
    &__container {
      input {
        height: 32px;
      }
    }

    &__value-container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.form-horizontal .has-feedback {
  &.is-react-select {
    &.has-error .react-select__control {
      border-color: #b94a48;
    }

    &.has-success .react-select__control {
      border-color: #468847;
    }

    .form-control-feedback {
      right: 80px;
      top: 1px;
    }
  }
}
