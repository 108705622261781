.paginated-table {
  button {
    .fa {
      width: 15px;
    }
  }

  button+button {
    margin-left: 3px;
  }

  .sortable {
    &:hover {
      cursor: pointer;
      background-color: #5b5b61;
    }

    .react-bootstrap-table-sort-order,
    .order {
      margin-left: 5px;
    }
  }
}

.paginated-table-with-actions {
  .input-column {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .actions-column {
    text-align: right;
    padding-top: 15px;
    padding-bottom: 14px;
  }

  table > tbody > tr.is-deleting > td {
    background-color: rgba(255, 0, 0, 0.2);
  }
}

.paginated-table-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & > div + div {
    margin-left: 15px;
  }
}

.paginated-table-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .items-per-page {
    display: flex;

    .form-group {
      display: flex;
      align-items: center;

      label {
        white-space: nowrap;
        margin-right: 5px;
      }
    }
  }

  .large {
    flex: 1px;
  }
}
