@import '../variables';

.react-alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  color: rgba(black, 0.75);
  padding: 15px;

  .message {
    width: 100%;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0px;
  }

  .close-button {
    margin: 10px;
    color: white;

    &:hover {
      cursor: pointer;
    }
  }

  [role="button"] {
    cursor: pointer;
  }

  &.outline {
    a {
      color: $orange;
    }
  }
}

.react-alert-danger {
  background-color: $danger;
  color: black;
}

.react-alert-info {
  background-color: $info;
}