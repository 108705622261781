.loading-state {
  width: 100%;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: #666;
  .loading-icon {
    margin-right: .5em;
  }
}
